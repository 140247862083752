@keyframes pulsate {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes beat {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(0.9);
    }
}

@keyframes beatCenter {
    0% {
        border-width: 3px;
    }
    50% {
        border-width: 1px;
    }
    100% {
        border-width: 3px;
    }
}

@keyframes colorchange {
    50% {color: lightgrey}
}

@-webkit-keyframes colorchange { /* Safari and Chrome - necessary duplicate */
    50% {color: lightgrey}
}