.flex {
  display: -webkit-flex;
  display: flex;
}

.center {
  justify-content: center;
  -webkit-justify-content: center;
}

.alignCenter {
  align-items: center;
  -webkit-align-items: center;
}

.alignStart {
  align-items: flex-start;
  -webkit-align-items: flex-start;
}

.alignEnd {
  align-items: flex-end;
  -webkit-align-items: flex-end;
}

.alignStretch {
  align-items: stretch;
  -webkit-align-items: stretch;
}

.alignSelfStart {
  align-self: flex-start;
  -webkit-align-self: flex-start;
}

.alignSelfEnd {
  align-self: flex-end;
  -webkit-align-self: flex-end;
}

.alignSelfCenter {
  align-self: center;
  -webkit-align-self: center;
}

.justifySelfCenter {
  justify-self: center;
  -webkit-justify-self: center;
}

.justifySelfEnd {
  justify-self: flex-end;
  -webkit-justify-self: flex-end;
}

.marginLeftAuto {
  margin-left: auto;
}

.row {
  flex-direction: row;
  -webkit-flex-direction: row;
}

.column {
  flex-direction: column;
  -webkit-flex-direction: column;
}

.grow {
  flex-grow: 1;
  -webkit-flex-grow: 1;
}

.noGrow {
  flex-grow: 0;
  -webkit-flex-grow: 0;
}

.noShrink {
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}

.spaceBetween {
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.spaceAround {
  justify-content: space-around;
  -webkit-justify-content: space-around;
}

.justifyStart {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.width100 {
  width: 100%;
}

.height100 {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.input {
  line-height: 30px;
  font-size: 13px;
  border: 1px solid lightgray;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: white;
}

.displayNone {
  display: none;
}

.hidden {
  visibility: hidden;
}

.relative {
  position: relative;
}

.inline {
  display: inline-block;
}

.svgPathFillCurrColor path {
  fill: currentColor;
}

.svgPathStrokeCurrColor path {
  stroke: currentColor;
}

.svgFillCurrColor path,
.svgFillCurrColor rect,
.svgFillCurrColor circle {
  fill: currentColor;
}

.halfCharSpace {
  margin-right: 1ch;
}

.charSpace {
  margin-right: 1ch;
}

.charSpaceLeft {
  margin-left: 1ch;
}

.charSpaceRight {
  margin-right: 1ch;
}

.separationLeft {
  margin-left: 20px;
}

.overflowEllipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
}

.scrollableY {
  overflow-y: auto;
}

.nowrap {
  white-space: nowrap;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .scrollable-y {
    overflow-x: hidden;
  }
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* spin */

.sgAnimateSpin {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spin;
  -moz-animation-duration: 1500ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spin;
  -ms-animation-duration: 1500ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  } to {
      -ms-transform: rotate(360deg);
    }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  } to {
      -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  } to {
      -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  } to {
      transform: rotate(360deg);
    }
}