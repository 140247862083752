.react-datepicker__navigation {
  cursor: pointer!important;
  width: 10px!important;
  padding: 0!important;
  height: 10px!important;
  min-width: initial!important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
  background-color: #02b5cc;
  border-radius: 0;
}