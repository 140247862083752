.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
  background-color: #02b5cc!important;
}

.react-time-picker select {
  appearance: none;
}

.react-time-picker input {
  border: none;
}

.react-time-picker__wrapper {
  border: none;
}